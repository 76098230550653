<template>
  <div>
    <div v-if="isRoleAdminOrDispatch && !project.is_cancelled">
      <div v-if="isAnyAdminDispatchReq > 0">
        <h6 class="heading-small text-muted mb-4">
          {{ i18n.$t("projectDetails.shifts.heading.ownShift") }}
        </h6>
        <div class="mb-5 card">
          <el-table class="table-responsive table-flush rounded" header-row-class-name="thead-light"
            header-cell-class-name="px-3" cell-class-name="px-3 py-2" :data="adminDispatchRequest">
            <el-table-column min-width="35"></el-table-column>
            <el-table-column :label="i18n.$t('projectDetails.shifts.th.shift')" prop="shift_name" min-width="200"
              sortable>
              <template v-slot="{ row }">
                <div class="media align-items-center cursor-pointer">
                  <div class="
                      media-body
                      text-uppercase
                      font-weight-600
                      text-sm text-default
                    ">
                    {{ row.shift_name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="210" :label="i18n.$t('projectDetails.shifts.th.date')"
              prop="shift_start_date_time" sortable>
              <template v-slot="{ row }">
                <div>
                  <span class="p-0 mr-1 text-xs text-left">
                    <i class="fas fa-calendar-alt text-gray mr-1"></i>
                    <span class="margin-left">
                      {{ formatDate(row.shift_date) }}
                    </span>
                  </span>
                  <span class="p-0 m-1 text-xs text-left">
                    <i class="fas fa-clock text-gray mr-1"></i>
                    <span class="margin-left">
                      {{ shiftTime(row.shift_start_time) }}
                    </span>
                  </span>
                </div>
                <div>
                  <span class="p-0 mr-1 text-xs text-left">
                    <i class="fas fa-calendar-alt text-gray mr-1"></i>
                    <span class="margin-left">
                      {{ formatDate(row.shift_end_date) }}
                    </span>
                  </span>
                  <span class="p-0 m-1 text-xs text-left">
                    <i class="fas fa-clock text-gray mr-1"></i>
                    <span class="margin-left">
                      {{ shiftTime(row.shift_end_time) }}
                    </span>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="120" :label="i18n.$t('projectDetails.shifts.th.discipline')"
              prop="shift_discipline" sortable>
              <template v-slot="{ row }">
                <div class="d-flex">
                  <span class="p-0 mr-1 text-xs text-left">
                    <i class="fas fa-archive text-gray mr-1"></i>
                    <span class="margin-left">
                      {{ row.shift_discipline }}
                    </span>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="i18n.$t('projectDetails.shifts.th.status')" prop="shift_status" min-width="140"
              sortable>
              <template v-slot="{ row }">
                <span class="p-0 mr-1 text-xs text-left">
                  <badge class="
                      badge-dot
                      text-xs
                      p-0
                      font-weight-600
                      badge-md
                      m-0
                      margin-left
                    " type="">
                    <i :class="`bg-${getUserResponseColor(
                      row.shift_request_status
                    )}`"></i>
                    <span class="status text-capitalize">
                      {{ getStatus(row.shift_request_status) }}
                    </span>
                  </badge>
                </span>
              </template>
            </el-table-column>
            <el-table-column min-width="140" class-name="text-right">
              <template v-slot="{ row }">
                <span v-if="
                  row.shift_request_status === shiftStatus.userTentative ||
                  row.shift_request_status === shiftStatus.userRequested ||
                  row.shift_request_status === shiftStatus.userAssigned
                " class="mt-2 mb-2" style="margin-left: 5px">
                  <button v-if="
                    row.shift_request_status !== shiftStatus.userAvailable &&
                    row.shift_request_status !== shiftStatus.userAssigned
                  " class="btn btn-sm" :class="row.shift_request_status === shiftStatus.userTentative
                    ? 'btn-outline-warning'
                    : 'btn-outline-dark'
                    " @click="
                      updateRequestStatusToMaybe(row, shiftStatus.userTentative)
                      ">
                    <i class="fas fa-question"></i>
                  </button>
                  <button class="btn btn-sm" :class="row.shift_request_status === shiftStatus.userAvailable
                    ? 'btn-outline-success'
                    : 'btn-outline-dark'
                    " @click="
                      updateRequestStatusToYes(row, shiftStatus.userAvailable)
                      ">
                    <i class="fas fa-check"></i>
                  </button>
                  <button class="btn btn-sm" :class="row.shift_request_status === shiftStatus.userUnavailable
                    ? 'btn-outline-danger'
                    : 'btn-outline-dark'
                    " @click="
                      updateRequestStatusToRejected(
                        row,
                        shiftStatus.userUnavailable
                      )
                      ">
                    <i class="fas fa-times"></i>
                  </button>
                </span>
                <span v-if="
                  row.shift_request_status == shiftStatus.userAdded ||
                  row.shift_request_status == shiftStatus.userAvailable
                ">
                  <button class="btn btn-sm btn-outline-dark btn-disable btn-hover" disabled>
                    <i class="fas fa-question"></i>
                  </button>

                  <button class="btn btn-sm btn-outline-success btn-hover" disabled>
                    <i class="fas fa-check"></i>
                  </button>

                  <button class="btn btn-sm btn-outline-dark btn-disable btn-hover" disabled>
                    <i class="fas fa-times"></i>
                  </button>
                </span>
              </template>
            </el-table-column>
            <el-table-column min-width="70" class-name="text-right">
              <template v-slot="{ row }">
                <el-dropdown class="dropdown" trigger="click" placement="bottom-start">
                  <span class="p-2 btn-icon-only text-light">
                    <i class="fas fa-ellipsis-v mt-2"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <li class="dropdown-item">
                        <div v-if="row.shift_description">
                          <el-popover placement="bottom" width="300" trigger="click" :content="row.shift_description">
                            <template #reference>
                              <span class="">
                                {{
                                  i18n.$t(
                                    "projectDetails.shifts.th.information"
                                  )
                                }}
                              </span>
                            </template>
                          </el-popover>
                        </div>
                        <div v-else>
                          <span class="">
                            {{
                              i18n.$t("projectDetails.shifts.th.information")
                            }}
                          </span>
                        </div>
                      </li>
                      <li class="dropdown-item">
                        <span @click="showDocument(row)">
                          {{ i18n.$t("projectDetails.document") }}
                        </span>
                      </li>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div> 
<!--
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("projectDetails.shifts.heading.personnelPlanning") }}
      </h6>
-->
      <div style="margin-bottom: 20px;"></div>
      <div class="card">
        <el-table class="table-responsive table-flush rounded" header-row-class-name="thead-light"
          header-cell-class-name="px-3" cell-class-name="px-3 py-2" :data="shifts" :row-key="(row) => row.shift_id"
          :expand-row-keys="expandRowKeys.value" @expand-change="expand" ref="shiftTable">
          <el-table-column min-width="30" type="expand">
            <template v-slot="{ row }">
              <div :class="`row ${row.project_id} no-gutters`">
                <!-- Requested -->
                <div class="col-4">
                  <h5 class="
                      text-uppercase
                      font-weight-bolder
                      text-xs text-default
                      mb-2
                      pb-2
                      border-bottom
                    ">
                    {{ i18n.$t("projectDetails.shifts.requestedUser") }}
                  </h5>
                  <shift-staff type="requested" :shiftName="row.shift_name"
                    :staffData="requestedStaff.value"></shift-staff>
                  <h6 v-if="requestedStaff.allValue.length > 10 && showAll == false">{{ requestedStaff.allValue.length -
                    10 }}
                    weitere</h6>
                </div>
                <!-- Available -->
                <div class="col-4">
                  <h5 class="
                      text-uppercase
                      font-weight-bolder
                      text-xs text-default
                      mb-2
                      pb-2
                      border-bottom
                    ">
                    {{ i18n.$t("projectDetails.shifts.availableUser") }}
                  </h5>
                  <shift-staff type="available" :shiftName="row.shift_name" :staffData="availableStaff.value"
                    :isModalAbort="abortModal" @updateRequest="updateAdminRequestApi"
                    @showConfirmation="showStatusModal"></shift-staff>
                  <h6 v-if="availableStaff.allValue.length > 10 && showAll == false">{{ availableStaff.allValue.length -
                    10 }}
                    weitere</h6>
                </div>
                <!-- Scheduled -->
                <div class="col-4">
                  <h5 class="
                      text-uppercase
                      font-weight-bolder
                      text-xs text-default
                      mb-2
                      pb-2
                      border-bottom
                    ">
                    {{ i18n.$t("projectDetails.shifts.scheduledUser") }}
                  </h5>
                  <shift-staff type="scheduled" :shiftName="row.shift_name" :staffData="scheduledStaff.value"
                    @remove="openRemoveBookedUserModal"></shift-staff>
                  <h6 v-if="scheduledStaff.allValue.length > 10 && showAll == false">{{ scheduledStaff.allValue.length -
                    10 }}
                    weitere</h6>
                </div>
                <base-button type="warning" class="btn-sm" v-if="scheduledStaff.allValue.length > 10
                  || availableStaff.allValue.length > 10
                  || requestedStaff.allValue.length > 10" @click="showAll = !showAll;
                  if (showAll) {
                    scheduledStaff.value = [...scheduledStaff.allValue];
                    availableStaff.value = [...availableStaff.allValue];
                    requestedStaff.value = [...requestedStaff.allValue];
                  } else {
                    scheduledStaff.value = scheduledStaff.value.splice(0, 10);
                    availableStaff.value = availableStaff.value.splice(0, 10);
                    requestedStaff.value = requestedStaff.value.splice(0, 10);
                  }

                  ">
                  {{ showAll ? 'weniger Anzeigen' : 'mehr Anzeigen' }}</base-button>

              </div>
            </template>
          </el-table-column>
          <el-table-column :label="i18n.$t('projectDetails.shifts.th.shift')" prop="shift_name" min-width="200"
            sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center cursor-pointer" @click="expand(row, true)">
                <div class="
                    media-body
                    text-uppercase
                    font-weight-600
                    text-sm text-default
                  ">
                  {{ row.shift_name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="210" :label="i18n.$t('projectDetails.shifts.th.date')"
            prop="shift_start_date_time" sortable>
            <template v-slot="{ row }">
              <div>
                <span class="p-0 mr-1 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ formatDate(row.shift_date) }}
                  </span>
                </span>
                <span class="p-0 m-1 text-xs text-left">
                  <i class="fas fa-clock text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ shiftTime(row.shift_start_time) }}
                  </span>
                </span>
              </div>
              <div>
                <span class="p-0 mr-1 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ formatDate(row.shift_end_date) }}
                  </span>
                </span>
                <span class="p-0 m-1 text-xs text-left">
                  <i class="fas fa-clock text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ shiftTime(row.shift_end_time) }}
                  </span>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="110" :label="i18n.$t('projectDetails.shifts.th.discipline')"
            prop="shift_discipline" sortable>
            <template v-slot="{ row }">
              <div class="d-flex">
                <span class="p-0 mr-1 text-xs text-left">
                  <i class="fas fa-archive text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ row.shift_discipline }}
                  </span>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="i18n.$t('projectDetails.shifts.th.personal')" min-width="140">
            <template v-slot="{ row }">
              <span class="m-0 m-1 text-xs text-left">
                <badge class="text-xs m-0 p-0 font-weight-600 badge-md margin-left" type="">
                  <i class="fas fa-user text-gray mr-1"></i>
                  <span class="status text-capitalize">
                    {{ row.team_count }}/{{ row.shift_count }}
                  </span>
                </badge>
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="140" class-name="text-right">
            <template v-slot="{ row }">
              <span data-toggle="tooltip" data-placement="top"
                :title="i18n.$t('projectDetails.shifts.buttons.scheduleStaff')" class="" @click="scheduleShift(row)">
                <i class="fa fa-user-plus"></i>
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="70" class-name="text-right">
            <template v-slot="{ row }">
              <el-dropdown class="dropdown" trigger="click" placement="bottom-start">
                <span class="p-2 btn-icon-only text-light">
                  <i class="fas fa-ellipsis-v mt-2"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <li class="dropdown-item">
                      <div v-if="row.shift_description">
                        <el-popover placement="bottom" width="300" trigger="click" :content="row.shift_description">
                          <template #reference>
                            <span class="">
                              {{
                                i18n.$t("projectDetails.shifts.th.information")
                              }}
                            </span>
                          </template>
                        </el-popover>
                      </div>
                      <div v-else>
                        <span class="">
                          {{ i18n.$t("projectDetails.shifts.th.information") }}
                        </span>
                      </div>
                    </li>
                    <li class="dropdown-item">
                      <span @click="showDocument(row)">
                        {{ i18n.$t("projectDetails.document") }}
                      </span>
                    </li>

                    <li class="dropdown-item">
                      <span @click="editShift(row)">
                        {{ i18n.$t("projectDetails.shifts.buttons.editShift") }}
                      </span>
                    </li>
                    <li class="dropdown-item" v-if="row.is_manager_shift === false">
                      <span @click="deleteShift(row)">
                        {{
                          i18n.$t("projectDetails.shifts.buttons.deleteShift")
                        }}
                      </span>
                    </li>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else>
      <h6 class="heading-small text-muted mb-4">
        {{ i18n.$t("projectDetails.shifts.heading.ownShift") }}
      </h6>
      <div class="card">
        <el-table class="table-responsive table-flush rounded" header-row-class-name="thead-light"
          header-cell-class-name="px-3" cell-class-name="px-3 py-2" :data="shifts">
          <el-table-column min-width="35"> </el-table-column>
          <el-table-column :label="i18n.$t('projectDetails.shifts.th.shift')" prop="shift_name" min-width="200"
            sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center cursor-pointer">
                <div class="
                    media-body
                    text-uppercase
                    font-weight-600
                    text-sm text-default
                  ">
                  {{ row.shift_name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="210" :label="i18n.$t('projectDetails.shifts.th.date')" prop="shift_date_time"
            sortable>
            <template v-slot="{ row }">
              <div>
                <span class="p-0 mr-1 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ formatDate(row.shift_date) }}
                  </span>
                </span>
                <span class="p-0 m-1 text-xs text-left">
                  <i class="fas fa-clock text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ shiftTime(row.shift_start_time) }}
                  </span>
                </span>
              </div>
              <div>
                <span class="p-0 mr-1 text-xs text-left">
                  <i class="fas fa-calendar-alt text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ formatDate(row.shift_end_date) }}
                  </span>
                </span>
                <span class="p-0 m-1 text-xs text-left">
                  <i class="fas fa-clock text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ shiftTime(row.shift_end_time) }}
                  </span>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="110" :label="i18n.$t('projectDetails.shifts.th.discipline')"
            prop="shift_discipline" sortable>
            <template v-slot="{ row }">
              <div class="d-flex">
                <span class="px-2 py-2 m-1 text-xs text-left">
                  <i class="fas fa-archive text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ row.shift_discipline }}
                  </span>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="!project.is_cancelled" min-width="210">
            <template v-slot="{ row }">
              <div class="d-flex p-1">
                <button class="btn btn-white p-1 text-xs text-left" type="button" @click="showProjectManager(row)">
                  <i class="fas fa-user text-gray mr-1"></i>
                  <span class="margin-left">
                    {{ i18n.$t("project.projectManagerModal.title") }}
                  </span>
                </button>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="i18n.$t('projectDetails.shifts.th.status')" prop="shift_status" min-width="140"
            sortable>
            <template v-slot="{ row }">
              <span class="p-0 mr-1 text-xs text-left">
                <badge class="
                    badge-dot
                    text-xs
                    p-0
                    font-weight-600
                    badge-md
                    m-0
                    margin-left
                  " type="">
                  <i :class="`bg-${getUserResponseColor(
                    row.shift_request_status
                  )}`"></i>
                  <span class="status text-capitalize">
                    {{ getStatus(row.shift_request_status) }}
                  </span>
                </badge>
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="140">
            <template v-slot="{ row }">
              <span v-if="
                !project.is_cancelled &&
                (row.shift_request_status === shiftStatus.userTentative ||
                  row.shift_request_status === shiftStatus.userRequested ||
                  row.shift_request_status === shiftStatus.userAssigned)
              " class="mt-2 mb-2">
                <button v-if="
                  row.shift_request_status !== shiftStatus.userAvailable &&
                  row.shift_request_status !== shiftStatus.userAssigned
                " class="btn btn-sm" :class="row.shift_request_status === shiftStatus.userTentative
                  ? 'btn-outline-warning'
                  : 'btn-outline-dark'
                  " @click="
                    updateRequestStatusToMaybe(row, shiftStatus.userTentative)
                    ">
                  <i class="fas fa-question"></i>
                </button>
                <button class="btn btn-sm" :class="row.shift_request_status === shiftStatus.userAvailable
                  ? 'btn-outline-success'
                  : 'btn-outline-dark'
                  " @click="
                    updateRequestStatusToYes(row, shiftStatus.userAvailable)
                    ">
                  <i class="fas fa-check"></i>
                </button>
                <button class="btn btn-sm" :class="row.shift_request_status === shiftStatus.userUnavailable
                  ? 'btn-outline-danger'
                  : 'btn-outline-dark'
                  " @click="
                    updateRequestStatusToRejected(
                      row,
                      shiftStatus.userUnavailable
                    )
                    ">
                  <i class="fas fa-times"></i>
                </button>
              </span>
              <span v-if="
                row.shift_request_status == shiftStatus.icUserAdded ||
                row.shift_request_status == shiftStatus.icUserAvailable ||
                row.shift_request_status == shiftStatus.userAdded ||
                row.shift_request_status == shiftStatus.userAvailable
              ">
                <button class="btn btn-sm btn-outline-dark btn-disable btn-hover" disabled>
                  <i class="fas fa-question"></i>
                </button>

                <button class="btn btn-sm btn-outline-success btn-hover" disabled>
                  <i class="fas fa-check"></i>
                </button>

                <button class="btn btn-sm btn-outline-dark btn-disable btn-hover" disabled>
                  <i class="fas fa-times"></i>
                </button>
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="70" class-name="text-right">
            <template v-slot="{ row }">
              <el-dropdown class="dropdown" trigger="click" placement="bottom-start">
                <span class="p-2 btn-icon-only text-light">
                  <i class="fas fa-ellipsis-v mt-2"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <li class="dropdown-item">
                      <div v-if="row.shift_description">
                        <el-popover placement="bottom" width="300" trigger="click" :content="row.shift_description">
                          <template #reference>
                            <span class="">
                              {{
                                i18n.$t("projectDetails.shifts.th.information")
                              }}
                            </span>
                          </template>
                        </el-popover>
                      </div>
                      <div v-else>
                        <span class="">
                          {{ i18n.$t("projectDetails.shifts.th.information") }}
                        </span>
                      </div>
                    </li>
                    <li v-if="userBookedForShift(row)" class="dropdown-item">
                      <span @click="showDocument(row)">
                        {{ i18n.$t("projectDetails.document") }}
                      </span>
                    </li>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- Maybe shift request modal -->
    <maybe-modal :showModal="shiftStatusModal.maybeModalVisible" @close="closeAllShiftRequestModal"
      @update="updateUserRequestApi">
    </maybe-modal>
    <!-- Rejected Modal -->
    <rejected-modal :requestType="shiftStatusModal.requestType" :showModal="shiftStatusModal.rejectedModalVisible"
      @close="closeAllShiftRequestModal" @rejected="showConfirmRejectedMoal">
    </rejected-modal>
    <!-- Confirm Rejected Modal -->
    <confirm-rejected-modal :showModal="shiftStatusModal.confirmRejectedModalVisible" @close="closeAllShiftRequestModal"
      @confirm="updateUserRequestApi"></confirm-rejected-modal>
    <!-- Available Modal -->
    <available-modal :requestType="shiftStatusModal.requestType" :showModal="shiftStatusModal.availableModalVisible"
      @close="closeAllShiftRequestModal" @available="showConfirmAvailableModal">
    </available-modal>
    <!-- Confirm available Modal -->
    <confirm-available-modal :showModal="shiftStatusModal.confirmAvailableModalVisible"
      @close="closeAllShiftRequestModal" @confirm="updateUserRequestApi"></confirm-available-modal>

    <!-- OC user booking popup modal -->
    <user-booking-modal v-if="shiftsRowData != null" :shiftData="shiftsRowData"
      :showModal="models.addUserBookingModalVisible" @close="closeModal" @update="updateAdminRequestApi">
    </user-booking-modal>

    <!-- Document Modal -->
    <add-document-modal v-if="models.addDocumentModalVisible" :loader="showLoader"
      :showModal="models.addDocumentModalVisible" @close="closeModalAdd" @add="createDocument"></add-document-modal>

    <!-- Remove document modal -->
    <delete-document-modal :showModal="models.removeDocumentModalVisible" :loader="showLoader"
      @close="closeDeleteDocumentModal" @confirm="showConfirmDocumentDeleteModal"></delete-document-modal>

    <confirm-document-delete-modal :showModal="models.confirmDeleteDocumentModalVisible" :loader="showLoader"
      @close="closeConfirmDocumentDeleteModal" @remove="sendRemoveDocumentAPI"></confirm-document-delete-modal>

    <list-documents-modal :documents="documents" :showModal="models.listDocumentModalVisible"
      :isAddAllowed="isRoleAdminOrDispatch" @close="closeListDocumentModal" @DeleteDocApi="removeDocumentAPI"
      @addDoc="addNewDocument">
    </list-documents-modal>

    <project-manager-list-modal :projectManagers="projectManagers" :showModal="models.showProjectManagerModalVisible"
      @close="closeProjectManagerModal" @addDoc="addNewDocument">
    </project-manager-list-modal>
    <remove-booked-user-modal :showModal="removeUserModal.removeBookedModalVisible" @close="closeRemoveBookedModal"
      @remove="removeBookedUser">
    </remove-booked-user-modal>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElPopover,
  ElLoading,
} from "element-plus";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  SHIFTSTATUS,
  PROJECTSTATUSCOLOR,
  SHIFTSTATUSCOLORDOT,
} from "@/util/constant";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import { useToast } from "vue-toastification";
import ShiftStaff from "../Shift/OCShiftStaff.vue";
import {
  GETALLSHIFTREQUESTAPI,
  UPDATESHIFTREQUESTAPI,
  UPDATEICSHIFTREQUESTAPI,
  CREATESHIFTDOCUMENTAPI,
  GETSHIFTDOCUMENTSAPI,
  DELETESHIFTDOCAPI,
  UPDATEOCMULTIPLEREQUESTAPI,
  REMOVEBOOKEDSHIFTUSERAPI,
} from "@/services/private/ProjectShift";
import { GETUSERPROJECTMANAGERAPI } from "@/services/private/Project";
import UserBookingModal from "../OCBooking/UserBookingModal.vue";
import MaybeModal from "../../Modals/ShiftModal/OCModal/MaybeModal.vue";
import RejectedModal from "../../Modals/ShiftModal/OCModal/RejectedModal.vue";
import ConfirmRejectedModal from "../../Modals/ShiftModal/OCModal/ConfirmRejectedModal.vue";
import AvailableModal from "../../Modals/ShiftModal/OCModal/AvailableModal.vue";
import ConfirmAvailableModal from "../../Modals/ShiftModal/OCModal/ConfirmAvailableModal.vue";

import AddDocumentModal from "../Document/AddDocumentModal.vue";
import DeleteDocumentModal from "../../../Common/DeletePopup/DeleteDocumentModal.vue";
import ConfirmDocumentDeleteModal from "../../../Common/DeletePopup/ConfirmDocumentDeleteModal.vue";
import ListDocumentsModal from "../Document/ListDocumentsModal.vue";
import ProjectManagerListModal from "../../Modals/ProjectManagerListModal.vue";
import RemoveBookedUserModal from "../../Modals/ShiftModal/OCModal/RemoveBookedUserModal.vue";

export default {
  props: {
    shiftData: Object,
    project: Object,
    adminDispatchRequestData: Object,
    isAnyAdminDispatchReq: Number,
  },
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElPopover.name]: ElPopover,
    ShiftStaff,
    MaybeModal,
    RejectedModal,
    ConfirmRejectedModal,
    AvailableModal,
    ConfirmAvailableModal,
    UserBookingModal,
    AddDocumentModal,
    DeleteDocumentModal,
    ConfirmDocumentDeleteModal,
    ListDocumentsModal,
    ProjectManagerListModal,
    RemoveBookedUserModal,
  },
  data() {
    return {
      shiftsRowData: null,
      showLoader: false,
      documents: [],
      projectManagers: [],
      loader: false,
      showAll: false,
      models: {
        addUserBookingModalVisible: false,
        addDocumentModalVisible: false,
        removeDocumentModalVisible: false,
        confirmDeleteDocumentModalVisible: false,
        showProjectManagerModalVisible: false,
        removeDocumentId: "",
        isAddAllowed: true,
      },
    };
  },
  computed: {
    isRoleAdminOrDispatch() {
      const user = storage().getUser();
      const role = user && user.role;
      return role && (role.role_code === "A" || role.role_code === "D");
    },
    shifts() {
      return this.shiftData;
    },
    adminDispatchRequest() {
      return this.adminDispatchRequestData;
    },
  },

  methods: {
    userBookedForShift(row) {
      let isUserBooked = false;
      if (row.shift_request_status == "user_added") {
        isUserBooked = true;
      }
      return isUserBooked;
    },
    showProjectManager(data) {
      this.getUserProjectManager(data);
    },
    showDocument(data) {
      this.models.listDocumentModalVisible = true;
      this.getDocuments(data);
    },
    closeListDocumentModal() {
      this.models.listDocumentModalVisible = false;
    },
    closeProjectManagerModal() {
      this.models.showProjectManagerModalVisible = false;
    },
    addNewDocument() {
      this.models.addDocumentModalVisible = true;
    },
    closeModalAdd() {
      this.models.addDocumentModalVisible = false;
    },
    deleteDocument(data) {
      this.models.removeDocumentId = data;
      this.models.removeDocumentModalVisible = true;
    },
    closeDeleteDocumentModal() {
      this.models.removeDocumentId = "";
      this.models.removeDocumentModalVisible = false;
    },
    showConfirmDocumentDeleteModal() {
      this.models.removeDocumentModalVisible = false;
      this.models.confirmDeleteDocumentModalVisible = true;
    },
    closeConfirmDocumentDeleteModal() {
      this.models.confirmDeleteDocumentModalVisible = false;
      this.closeDeleteDocumentModal();
    },
    async sendRemoveDocumentAPI() {
      const toast = useToast();
      //Call API
      const doc_id = this.models.removeDocumentId;
      const result = await DELETESHIFTDOCAPI(doc_id);

      if (result && result.status === 200) {
        this.closeConfirmDocumentDeleteModal();
        this.getDocuments();
        //this.$emit("update");
        toast.success(this.i18n.$t("location.delete.successDeleteMessage"));
      } else {
        toast.error(this.i18n.$t("location.delete.failDeleteMessage"));
      }
    },
    openDocument(data) {
      window.open(data.project_document_path, "_blank");
    },
    async getDocuments(data) {
      this.shiftsRowData = data;
      let id_Document = data.shift_id; //this.route.params.id;
      let is_Deleted = false;
      var result = await GETSHIFTDOCUMENTSAPI(id_Document, is_Deleted);
      if (result && result.status == 200) {
        this.documents = result.data.data.map((a) => ({
          document_id: a.shift_document_id,
          document_name: a.shift_document_name,
          document_path: a.shift_document_path,
        }));
      }
    },
    async getUserProjectManager(data) {
      const toast = useToast();
      var result = await GETUSERPROJECTMANAGERAPI(data.project_id);
      if (
        result &&
        result.status == 200 &&
        result.data &&
        result.data.data.length
      ) {
        this.models.showProjectManagerModalVisible = true;
        this.projectManagers = result.data.data;
      } else {
        toast.error(this.i18n.$t("project.projectManagerModal.noRecordError"));
      }
    },

    async createDocument(value) {
      const toast = useToast();
      this.showLoader = true;
      let formData = new FormData();
      let shiftId = this.shiftsRowData.shift_id;
      formData.append("file", value.file);
      formData.append("shift_id", shiftId);
      formData.append("shift_document_name", value.documentName);
      if (value.file.type != "application/pdf") {
        toast.error(this.i18n.$t("location.locationDocument.errorPdfAllow"));
        this.showLoader = false;
        return;
      }

      let result = await CREATESHIFTDOCUMENTAPI(formData);
      this.showLoader = false;
      if (result && result.status == 200) {
        toast.success(
          this.i18n.$t("location.locationDocument.uploadDocSuccess")
        );
        this.updateDocument();
      }
    },
    async removeDocumentAPI(id) {
      const toast = useToast();
      //Call API
      const doc_id = id;
      const result = await DELETESHIFTDOCAPI(doc_id);

      if (result && result.status === 200) {
        this.closeConfirmDocumentDeleteModal();
        this.getDocuments(this.shiftsRowData);
        toast.success(this.i18n.$t("location.delete.successDeleteMessage"));
      } else {
        toast.error(this.i18n.$t("location.delete.failDeleteMessage"));
      }
    },
    updateDocument() {
      this.getDocuments(this.shiftsRowData);
      this.models.addDocumentModalVisible = false;
    },
    scheduleShift(data) {
      document.body.classList.add("modal-open");
      this.shiftsRowData = data;
      this.models.addUserBookingModalVisible = true;
    },
    closeModal() {
      document.body.classList.remove("modal-open");
      this.shiftsRowData = null;
      this.models.addUserBookingModalVisible = false;
    },
    editShift(shiftData) {
      this.$emit("edit", shiftData);
    },
    deleteShift(shiftData) {
      this.$emit("delete", shiftData);
    },
  },
  setup(props, context) {
    const i18n = useI18n();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const shiftStatus = reactive(SHIFTSTATUS);
    const expandRowKeys = reactive({ value: [] });
    const requestedStaff = reactive({ value: [], allValue: [] });
    const availableStaff = reactive({ value: [], allValue: [] });
    const scheduledStaff = reactive({ value: [], allValue: [] });
    const request_feedback = ref("");
    const shiftStatusModal = reactive({
      maybeModalVisible: false,
      rejectedModalVisible: false,
      confirmRejectedModalVisible: false,
      availableModalVisible: false,
      confirmAvailableModalVisible: false,
      requestType: "",
    });
    const updateShiftRequestData = reactive({
      user_name: "",
      shift_request_id: "",
      shift_id: "",
      user_id: "",
      shift_name: "",
      shift_request_status: "",
      partner_company_id: "",
      updateType: "",
      requests: [],
    });
    let loader = ref(false);
    let abortModal = ref(false);

    const removeUserModal = reactive({
      removeBookedModalVisible: false,
      removeShiftTeamId: "",
      removeShiftId: "",
    });

    function formatDate(projectDate) {
      if (projectDate !== null) {
        return date().formatDate(projectDate, "DD.MM.YYYY");
      }
      return "-";
    }
    function shiftTime(shiftStartTime) {
      if (shiftStartTime !== null) {
        return `${date().formatTime(`${shiftStartTime}`, "HH:mm")} Uhr`;
      }
      return "-";
    }
    function getProjectStatusColor(status) {
      return PROJECTSTATUSCOLOR[status];
    }
    function getStatus(status) {
      return this.i18n.$t(`projectDetails.shiftRequest.status.${status}`);
    }
    function getUserResponseColor(status) {
      return SHIFTSTATUSCOLORDOT[status];
    }
    async function expand(row, shiftView) {
      const { shift_id } = row;
      const lastId = expandRowKeys.value[0];
      expandRowKeys.value = shift_id === lastId ? [] : [shift_id];
      requestedStaff.value = [];
      availableStaff.value = [];
      scheduledStaff.value = [];
      if (expandRowKeys.value.length > 0) {
        let loadingInstance = ElLoading.service({
          fullscreen: true,
          lock: true,
        });
        await getRequestedStaff(shift_id);
        context.emit("update");
        setTimeout(() => {
          loadingInstance.close();
        }, 100);

        // Expand shift will highlight if User click notification shifts
        if (shiftView) {
          let expandedCell = document.getElementsByClassName(
            "el-table__expanded-cell"
          );
          expandedCell && expandedCell[0].classList.add("highlightExpand");
          expandedCell[0].scrollIntoView(false);
          setTimeout(() => {
            expandedCell && expandedCell[0].classList.remove("highlightExpand");
          }, 5000);
        }
      }
    }
    async function updateAdminRequestApi(shift_id) {
      requestedStaff.value = [];
      requestedStaff.allValue = [];
      availableStaff.value = [];
      availableStaff.allValue = [];
      scheduledStaff.value = [];
      scheduledStaff.allValue = [];

      context.emit("update");
      await getRequestedStaff(shift_id);
    }
    async function getRequestedStaff(shift_id) { //limit -1 is all
      const resultShiftRequest = await GETALLSHIFTREQUESTAPI({
        shift_id,
        flag: false,
      });
      if (resultShiftRequest && resultShiftRequest.status === 200) {
        const { data } = resultShiftRequest.data;
        requestedStaff.value = [];
        requestedStaff.allValue = [];
        availableStaff.value = [];
        availableStaff.allValue = [];
        scheduledStaff.value = [];
        scheduledStaff.allValue = [];


        if (data[0].requestedUserList && data[0].requestedUserList.length > 0) {
          requestedStaff.allValue = [...data[0].requestedUserList];
          requestedStaff.value = [...data[0].requestedUserList].splice(0, 10);
        }
        if (data[0].availableUserList && data[0].availableUserList.length > 0) {
          const availableUserList = data[0].availableUserList.map((d) => {
            return { ...d, is_selected: false };
          });
          availableStaff.allValue = [...availableUserList];
          availableStaff.value = [...availableUserList].splice(0, 10);
        }
        if (data[0].scheduleUserList && data[0].scheduleUserList.length > 0) {
          scheduledStaff.value = [...data[0].scheduleUserList];
          scheduledStaff.allValue = [...data[0].scheduleUserList].splice(0, 10);
        }
      }
    }
    function closeAllShiftRequestModal() {
      shiftStatusModal.maybeModalVisible = false;
      shiftStatusModal.rejectedModalVisible = false;
      shiftStatusModal.confirmRejectedModalVisible = false;
      shiftStatusModal.availableModalVisible = false;
      shiftStatusModal.confirmAvailableModalVisible = false;
      abortModal.value = false;
      loader.value = false;
    }
    function updateShiftData(row, status) {
      const user = storage().getUser();
      updateShiftRequestData.shift_request_id = row.shift_request_id;
      updateShiftRequestData.shift_id = row.shift_id;
      updateShiftRequestData.shift_request_status = status;
      updateShiftRequestData.shift_name = row.shift_name;
      updateShiftRequestData.user_name = `${user && user.first_name} ${user && user.last_name
        }`;
      updateShiftRequestData.user_id = user && user.user_id;
      shiftStatusModal.requestType = status;
    }
    function emptyShiftDataOnRequestUpdate() {
      updateShiftRequestData.shift_request_id = "";
      updateShiftRequestData.shift_id = "";
      updateShiftRequestData.shift_request_status = "";
      updateShiftRequestData.shift_name = "";
      updateShiftRequestData.user_name = "";
      updateShiftRequestData.user_id = "";
      updateShiftRequestData.partner_company_id = "";
      updateShiftRequestData.updateType = "";
      updateShiftRequestData.requests = [];
      shiftStatusModal.requestType = "";
      request_feedback.value = "";
    }
    function updateRequestStatusToMaybe(row, status) {
      updateShiftData(row, status);
      closeAllShiftRequestModal();
      shiftStatusModal.maybeModalVisible = true;
    }
    function updateRequestStatusToRejected(row, status) {
      updateShiftData(row, status);
      closeAllShiftRequestModal();
      shiftStatusModal.rejectedModalVisible = true;
    }
    function showConfirmRejectedMoal(formData) {
      closeAllShiftRequestModal();
      request_feedback.value = formData.request_feedback;
      shiftStatusModal.confirmRejectedModalVisible = true;
    }
    function showConfirmAvailableModal() {
      closeAllShiftRequestModal();
      shiftStatusModal.confirmAvailableModalVisible = true;
    }
    function updateRequestStatusToYes(row, status) {
      const updateStatus =
        row.shift_request_status === shiftStatus.userAssigned
          ? shiftStatus.userAdded
          : status;
      updateShiftData(row, updateStatus);
      closeAllShiftRequestModal();
      shiftStatusModal.availableModalVisible = true;
    }
    async function updateUserRequestApi(formData) {
      loader.value = true;
      let feedback =
        formData && formData.request_feedback
          ? formData.request_feedback
          : request_feedback.value;
      const user = storage().getUser();
      const role = user.role;
      const requestType = shiftStatusModal.requestType;

      //Adding Admin/Dispatch user name if request type is user_perhaps or user_added
      if (
        (role.role_code === "A" || role.role_code === "D") &&
        (requestType === shiftStatus.userPerhaps ||
          requestType === shiftStatus.userRejected ||
          requestType === shiftStatus.icOpenRejected ||
          requestType === shiftStatus.icOpenPerhaps)
      ) {
        feedback = `${user.first_name} ${user.last_name}: ${feedback}`;
      }
      let data = {
        ...updateShiftRequestData,
        request_feedback: feedback,
      };
      let result = null;
      if (updateShiftRequestData.updateType === "BATCH") {
        result = await UPDATEOCMULTIPLEREQUESTAPI(data);
        loader.value = false;
      } else {
        if (updateShiftRequestData.partner_company_id !== "") {
          result = await UPDATEICSHIFTREQUESTAPI(data);
        } else {
          result = await UPDATESHIFTREQUESTAPI(data);
        }
        loader.value = false;
      }
      if (result && result.status === 200) {
        const shift_request_status =
          updateShiftRequestData.shift_request_status;
        const shift_id = updateShiftRequestData.shift_id;
        let swalMessage;
        if (shift_request_status === "user_rejected") {
          swalMessage = i18n.$t(`projectDetails.shifts.response.maybeResponse`);
        } else if (shift_request_status === "user_tentative") {
          const swalMessage = `Das ${updateShiftRequestData.shift_name} wurde erfolgreich gelöscht!`;
        } else {
          if (shift_request_status === shiftStatus.icOpenPerhaps) {
            swalMessage = i18n.$t(`projectDetails.shifts.swal.OC.updateMaybe`);
          }
          if (shift_request_status === shiftStatus.icUserAdded) {
            swalMessage = i18n.$t(
              `projectDetails.shifts.swal.OC.updateConfirm`
            );
          }
          if (shift_request_status === shiftStatus.icOpenRejected) {
            swalMessage = i18n.$t(
              `projectDetails.shifts.swal.OC.updateRejected`
            );
          }
        }
        const status = i18n.$t(`projectDetails.shifts.${shift_request_status}`);
        closeAllShiftRequestModal();
        emptyShiftDataOnRequestUpdate();
        updateAdminRequestApi(shift_id);
        context.emit("update");
        swal.fire({
          icon: "success",
          title: status,
          text: swalMessage,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        toast.error(i18n.$t("projectDetails.shiftRequest.shiftRequestFailed"));
      }
    }
    function availableUpdateShiftData(row, status) {
      updateShiftRequestData.shift_request_id = row.shift_request_id;
      updateShiftRequestData.shift_id = row.shift_id;
      updateShiftRequestData.shift_request_status = status;
      updateShiftRequestData.shift_name = row.shift_name;
      updateShiftRequestData.user_name = row.user_name;
      updateShiftRequestData.user_id = row.user_id;
      updateShiftRequestData.partner_company_id = row.partner_company_id;
      shiftStatusModal.requestType = status;
    }

    function showStatusModal({ data, type }) {
      updateShiftRequestData.updateType = type;
      let shift_request_status = "";
      if (type === "BATCH") {
        updateShiftRequestData.requests = data;
        updateShiftRequestData.shift_id = data[0].shift_id;
        shiftStatusModal.requestType = data[0].shift_request_status;
        shift_request_status = data[0].shift_request_status;
      } else {
        shiftStatusModal.requestType = data.shift_request_status;
        shift_request_status = data.shift_request_status;
        availableUpdateShiftData(data, data.shift_request_status);
      }
      abortModal.value = true;
      shiftStatusModal.maybeModalVisible = false;
      shiftStatusModal.rejectedModalVisible = false;
      shiftStatusModal.confirmRejectedModalVisible = false;
      shiftStatusModal.availableModalVisible = false;
      shiftStatusModal.confirmAvailableModalVisible = false;
      if (
        shift_request_status === shiftStatus.userPerhaps ||
        shift_request_status === shiftStatus.icOpenPerhaps
      ) {
        shiftStatusModal.maybeModalVisible = true;
      } else if (
        shift_request_status === shiftStatus.userAdded ||
        shift_request_status === shiftStatus.icUserAdded
      ) {
        shiftStatusModal.availableModalVisible = true;
      } else {
        shiftStatusModal.rejectedModalVisible = true;
      }
    }
    function isAdminOrDispatch() {
      const user = storage().getUser();
      const role = user && user.role;
      return role && (role.role_code === "A" || role.role_code === "D");
    }
    function closeRemoveBookedModal() {
      removeUserModal.removeBookedModalVisible = false;
      removeUserModal.removeShiftTeamId = "";
      removeUserModal.removeShiftId = "";
    }
    function openRemoveBookedUserModal(row) {
      removeUserModal.removeBookedModalVisible = true;
      removeUserModal.removeShiftTeamId = row.shift_request_id;
      removeUserModal.removeShiftId = row.shift_id;
    }
    async function removeBookedUser() {
      const shift_request_id = removeUserModal.removeShiftTeamId;
      const shift_id = removeUserModal.removeShiftId;
      loader.value = true;
      const result = await REMOVEBOOKEDSHIFTUSERAPI(shift_request_id);
      loader.value = false;
      if (result && result.status === 200) {
        closeRemoveBookedModal();
        await getRequestedStaff(shift_id, 10);
        context.emit("update");
        const swalMessage = i18n.$t(
          "projectDetails.removeBookedUser.responseSuccess"
        );
        const status = i18n.$t(
          "projectDetails.removeBookedUser.responseHeader"
        );
        swal.fire({
          icon: "success",
          title: status,
          text: swalMessage,
          buttonsStyling: false,
          customClass: { confirmButton: "btn btn-success" },
        });
      } else {
        toast.error(i18n.$t("projectDetails.removeBookedUser.responseFailed"));
      }
    }

    onMounted(() => {
      if (route.name == "ShiftDetails" || route.name == "ShiftDetailsExtra") {
        const shift_id = route.params.shift_id;
        let shiftCurrent = props.shiftData.find((a) => a.shift_id == shift_id);
        if (isAdminOrDispatch()) {
          expand(shiftCurrent, true, 10);
        } else {
          let shiftIndex = props.shiftData.findIndex(
            (a) => a.shift_id == shift_id
          );
          let expandedCell = document.getElementsByClassName("el-table__row");
          if (shiftIndex != -1 && expandedCell.length > 0) {
            expandedCell[shiftIndex].classList.add("highlightExpand");
            expandedCell[shiftIndex].scrollIntoView();
            setTimeout(() => {
              expandedCell[shiftIndex].classList.remove("highlightExpand");
            }, 5000);
          }
        }
      }
    });

    return {
      i18n,
      shiftStatus,
      expandRowKeys,
      requestedStaff,
      availableStaff,
      scheduledStaff,
      shiftStatusModal,
      loader,
      abortModal,
      updateShiftRequestData,
      request_feedback,
      closeAllShiftRequestModal,
      emptyShiftDataOnRequestUpdate,
      updateRequestStatusToMaybe,
      updateRequestStatusToYes,
      updateRequestStatusToRejected,
      showConfirmRejectedMoal,
      showConfirmAvailableModal,
      updateAdminRequestApi,
      updateUserRequestApi,
      formatDate,
      shiftTime,
      getProjectStatusColor,
      getStatus,
      expand,
      showStatusModal,
      getUserResponseColor,
      closeRemoveBookedModal,
      openRemoveBookedUserModal,
      removeBookedUser,
      removeUserModal,
    };
  },
};
</script>
<style>
.el-popover {
  z-index: 5000 !important;
}

.highlightExpand {
  background-color: #e5e5e5 !important;
}
</style>
<style scoped lang="scss">
.project_status {

  &:disabled,
  &[readonly] {
    background-color: #fff !important;
  }
}

.datepicker {

  &:disabled,
  &[readonly] {
    background-color: #fff !important;
  }
}

body.modal-open {
  overflow: hidden;
}

.project-rows {
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin-right: 0px;
  cursor: pointer;
}

.project-col {
  padding-left: 0px;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  font-size: 0.875rem;
}

.margin-left {
  color: #212529;
  margin-left: 5px;
}

.dropdown-item {
  text-align: center;
}

.el-dropdown-menu {
  background-color: #e5e5e5 !important;
}

.btn-hover:hover {
  box-shadow: none;
  transform: none;
}

.btn {
  height: 29px;
}
</style>