let API = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BACKEND_URL : 'http://locahost:8000';


//Auth
export const BASEURL = `${API}/timetrail/v1/api`;
export const REGISTER = `/public/auth/register`;
export const VERIFICATIONREGISTER = `public/auth/phone/verification/register`;
export const CODECHECKREGISTER = `public/auth/phone/verificationcheck/register`;
export const VERIFICATIONLOGIN = `public/auth/phone/verification/login`;
export const CODECHECKLOGIN = `public/auth/phone/verificationcheck/login`;
export const LOGIN = '/public/auth/login';
export const REFRESHTOKEN = '/public/auth/refreshtoken';
export const CHECKACTIVATION = '/public/auth/activate';
export const GENERATENEWACTIVATION = '/public/auth/resend/activation';
export const FORGOTPASSWORD = '/public/auth/forgotpassword';
export const VERIFYFORGOTPASSWORD = '/public/auth/verify/forgotpassword';
export const RESETPASSWORD = '/public/auth/resetpassword';
export const LOGOUT = '/private/auth/logout';

//User
export const COMPANYUSER = '/private/user/getbycompany/active';
export const GETUSERINFO = '/private/user/getbyid';
export const UPDATEPROFILE = '/private/user/update/profile';
export const UPDATEPERSONALINFO = '/private/user/update/personalinfo';
export const CLICKNOTIFICATIONBELL = '/private/user/update/notified';

//Role
export const GETALLROLE = '/private/role/getall';

//UserRole
export const INVITELINK = '/private/userrole/get/invitelink';
export const CHECKJOINCOMPANY = '/public/userrole/verify';
export const CREATEROLE = '/private/userrole/create';
export const CHANGEROLE = '/private/userrole/update';
export const DISCONNECTUSER = '/private/userrole/deactivate';

//RolePermission
export const GETUSERPERMISSION = '/private/rolepermission/getbyid';

//Notification
export const GETNOTOFICATION = '/private/notification/getall';
export const READONENOTIFICATION = '/private/notification/read';
export const CLEARALLNOTIFICATION = '/private/notification/deleteall';
export const CLEARNOTIFICATIONBYID = '/private/notification/deletebyid';

//PushNotification
export const SENDPUSHNOTIFICATION = '/private/pushnotification/send';

//Company
export const GETEMPLOYEEPROFILE = '/private/user/getbyid';
export const GETCOMPANYPROFILE = '/private/company/getbyid';

//Network
export const INVITELINKNETWORK = '/private/network/get/networklink';
export const GETALLNETWORK = '/private/network/getall/active';
export const VERIFYINVITETOKEN = '/public/network/verify';
export const DISCONNECTUSERFROMNETWORK = '/private/network/deactivate';
export const CREATENETWORK = '/private/network/create';
export const UPDATEFAVORITE = '/private/network/update/favorite';

//Project
export const COMPANYPROJECT = '/private/project/getall';
export const GETPRJECTBYUSER = '/private/project/getbyuser';
export const GETPROJECTSHIFTBYUSER = '/private/project/getshiftsbyuser';
export const GETPROJECTBYLOCATION = '/private/project/getbylocation';
export const CREATEPROJECT = '/private/project/create';
export const GETPROJECTBYID = '/private/project/getbyid';
export const UPDATEPROJECT = '/private/project/update';
export const DELETEPROJECT = '/private/project/delete';
export const CANCELEDPROJECT = '/private/project/cancel';
export const UPLOADPROJECTDOCUMENT = '/private/projectdocument/create';
export const GETPROJECTDOCUMENTS = '/private/projectdocument/getallbyproject';
export const DELETEPROJECTDOCUMENT = '/private/projectdocument/delete';
export const GETUSERPROJECTMANAGER = 'private/project/getuserprojectmanager';

//Project Shifts
export const GETPROJECTSHIFTS = '/private/shift/getallbyproject';
export const GETOTHERUSERROLEPROJECTSHIFTS = '/private/shift/getallbyuser';
export const GETSHIFTBYID = '/private/shift/getbyid';
export const CREATESHIFT = '/private/shift/create';
export const UPDATESHIFT = '/private/shift/update';
export const DELETESHIFT = '/private/shift/delete';
export const GETALLSHIFTREQUEST = '/private/shiftrequest/getallshiftrequest';
export const GETSHIFTREQUEST = '/private/shiftrequest/getallbyshift';
export const GETSHIFTPARTNERREQUEST = '/private/shiftrequest/getallrequestbypartner';
export const GETALLSHIFTMEMBER = '/private/shiftteam/getallbyshift';
export const UPDATESHIFTREQUEST = '/private/shiftrequest/update';
export const UPDATEICSHIFTREQUEST = '/private/icshiftrequest/update';
export const UPDATEOCMULTIPLEREQUEST = '/private/shiftrequest/updatemultiple';
export const REMOVEBOOKEDSHIFTUSER = '/private/shiftrequest/delete';

//Locations
export const GETALLLOCATIONS = '/private/location/getall/deleted';
export const GETLOCATIONBYID = '/private/location/getbyid';
export const ADDNEWLOCATION = '/private/location/create';
export const DELETELOCATION = '/private/location/delete';
export const UPDATELOCATION = '/private/location/update';
export const UPDATELOCATIONTEXT = '/private/location/update/locationtext';
export const UPLOADLOCATIONDOCUMENT = '/private/locationdocument/create';
export const GETLOCATIONDOCUMENTS = '/private/locationdocument/getallbylocation';
export const DELETELOCATIONDOCUMENT = '/private/locationdocument/delete';

//Shift oc booking Request
export const CREATESHIFTREQUEST = '/private/shiftrequest/create';
export const GETALLBYSHIFT = '/private/shiftrequest/getallbyshift';

//shift partner booking
export const GETSHIFTBYPARTNER = '/private/shift/getallbypartneruser';
export const GETALLBYPARTNERSHIFT = '/private/shiftpartnerrequest/getallbyshift';
export const CREATESHIFTPARTNERREQUEST = '/private/shiftpartnerrequest/create';
export const UPDATESHIFTPARTNERREQUEST = '/private/shiftpartnerrequest/update';

//Shift document
export const CREATESHIFTDOCUMENT = '/private/shiftdocument/create';
export const GETSHIFTDOCUMENTS = '/private/shiftdocument/getallbyshift';
export const DELETESHIFTDOC = '/private/shiftdocument/delete';

//Skills
export const GETALLSKILLS= '/private/skills/getallskills';
export const ADDNEWSKILL = '/private/skills/create';
export const DELETESKILL = '/private/skills/delete';
export const GETUSER = '/private/skills/getuser';
export const UNLOCKUSER = '/private/skills/unlockuser';
export const DELETEUSER = '/private/skills/unlockuser';
export const GETSKILLIDBYUSER = '/private/skills/getskillidbyuser';
export const GETCLOUDIDBYSKILL = '/private/skills/getcloudidbyskill';
export const GETPUPLICCLOUDIDBYSKILL = '/public/skills/getcloudidbyskill';
export const SETSKILLWATCHED = 'private/skills/watched';
export const GETSKILLWATCHED = 'private/skills/watched';
export const GETSKILLUNWATCHED = 'private/skills/unwatched';
export const GETSKILLIDBYCOMPANY = 'private/skills/getskillidbycompany';

//User action
export const GETUSERACTION = 'private/user/action';

//User device
export const GETUSERDEVICE = 'private/user/device';

//Time Tracking
export const GETTIMETRACKINGBYPROJECTID = 'private/timeRecords/getbyprojectid';
