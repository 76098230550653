import axios from '../Axios';
import { GETTIMETRACKINGBYPROJECTID } from '../CONSTANTURL';

export const GETTIMETRACKINGBYPROJECTIDAPI = async (data) => {
  try {
    const { project_id } = data;
    const result = await axios.get(`${GETTIMETRACKINGBYPROJECTID}/${project_id}`);
    return result;
  } catch (err) {
    return {
      error: true,
      response: err && err.response,
      message: err && err.message
    };
  }
};