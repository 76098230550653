<template>
  <div>
    <base-header type="default" class="pt-4 pb-6"></base-header>
    <div class="container-fluid mt--6 mb-4" v-if="isAccessAllowed">
      <div class="row">
        <div class="col-xl-12" v-if="isRoleAdminOrDispatch && !projectData.value.is_cancelled">
          <edit-project-form v-if="isProjectDataLoaded" :project="projectData.value" :shiftData="shifts.value"
            :shiftStartDateTime="shiftStartDateTime" :shiftEndDateTime="shiftEndDateTime"
            :projectStartDateTime="projectStartDateTime" :projectEndDateTime="projectEndDateTime"
            @update="getProjectDetails" :isOCProject="isOCProject"></edit-project-form>
        </div>
        <div class="col-xl-12" v-else>
          <other-user-project-form v-if="isProjectDataLoaded" :project="projectData.value" :shiftData="shifts.value"
            :shiftStartDateTime="shiftStartDateTime" :shiftEndDateTime="shiftEndDateTime"
            :projectStartDateTime="projectStartDateTime" :projectEndDateTime="projectEndDateTime"
            @update="getProjectDetails" :isOCProject="isOCProject"></other-user-project-form>
        </div>
        <div class="col-xl-12">
            <base-button v-if="isRoleAdminOrDispatch" class="mb-2 mr-0 mr-sm-2 mb-sm-0" size="sm" :type="!showTimeTracking ? 'warning' : 'secondary'"
            @click="toggleShowShift(!showShifts)">
            {{ i18n.$t("projectDetails.shifts.heading.personnelPlanning") }}
            </base-button>
            <base-button v-if="timeTrackingData.value.length > 0" class="mb-2 mr-0 mr-sm-2 mb-sm-0" size="sm" 
            :type="showTimeTracking ? 'warning' : 'secondary'"
            @click="toggleShowTimeTracking(!showTimeTracking.value)">
            Zeiterfassung
            </base-button>
        </div>
        <div class="col">
          <div v-if="isOCProject === true">
            <shift-details v-if="isProjectDataLoaded && isShiftDataLoaded && showShifts" :project="projectData.value"
              :shiftData="shifts.value" :adminDispatchRequestData="adminDispatchShiftsRequest.value"
              :isAnyAdminDispatchReq="adminDispatchShiftsRequest.value &&
                adminDispatchShiftsRequest.value.length
                " @edit="openEditMode" @delete="confirmDeleteShift" @update="getShiftsDetails"></shift-details>
            <div class="d-flex justify-content-end" v-if="
              currentuser_role &&
              currentuser_role.role_code &&
              (currentuser_role.role_code === 'A' ||
                currentuser_role.role_code === 'D') &&
              !projectData.value.is_cancelled &&
              showShifts
            ">
              <button class="btn btn-warning btn-sm" @click="toggleManagerShiftModal(!addShiftModalVisible)">
                {{
                  i18n.$t("projectDetails.shifts.buttons.addProjectManagement")
                }}
              </button>
              <button class="btn btn-warning btn-sm" @click="toggleAddShiftModal(!addShiftModalVisible)">
                {{ i18n.$t("projectDetails.shifts.buttons.addShift") }}
              </button>
            </div>
          </div>
          <div v-else-if="isOCProject === false">
            <IC-shift-details v-if="isShiftDataLoaded" :project="projectData.value" :shiftData="shifts.value"
              :adminDispatchRequestData="adminDispatchShiftsRequest.value" :isAnyAdminDispatchReq="adminDispatchShiftsRequest.value &&
                adminDispatchShiftsRequest.value.length
                " @edit="openEditMode" @update="getICAdminShiftDetails"></IC-shift-details>
          </div>
          <div v-if="showTimeTracking === true">
            <time-tracking-details :timeTracking="timeTrackingData.value"></time-tracking-details>
          </div>
        </div>
      </div>
    </div>
    <add-shift-modal :details="projectData.value" :showModal="addShiftModalVisible" :loader="loader" :mode="shiftMode"
      :shiftData="shiftData.value" @close="hideAddShiftModal" @create="addShiftApi"
      @edit="updateShiftApi"></add-shift-modal>
    <delete-shift-modal :showModal="deleteShiftModalVisible" :loader="loader" @close="hideDeleteShiftModal"
      @delete="deleteShiftApi"></delete-shift-modal>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import EditProjectForm from "./ProjectDetails/EditProjectForm.vue";
import OtherUserProjectForm from "./ProjectDetails/OtherUserProjectForm.vue";
import ShiftDetails from "./ProjectDetails/OCBooking/ShiftDetails.vue";
import ICShiftDetails from "./ProjectDetails/ICBooking/ICShiftDetails.vue";
import TimeTrackingDetails from './ProjectDetails/TimeTracking/TimeTrackingDetails.vue';
import AddShiftModal from "./Modals/AddShiftModal.vue";
import DeleteShiftModal from "./Modals/DeleteShiftConfirmation.vue";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import { currentuser_role } from "@/services/private/Role_Service";
import {
  GETPROJECTBYIDAPI,
  UPDATEPROJECTAPI,
} from "@/services/private/Project";
import {
  GETPROJECTSHIFTSAPI,
  CREATESHIFTAPI,
  GETOTHERUSERROLEPROJECTSHIFTSAPI,
  GETSHIFTBYPARTNERAPI,
  UPDATESHIFTAPI,
  DELETESHIFTAPI,
} from "@/services/private/ProjectShift";
import { GETTIMETRACKINGBYPROJECTIDAPI } from "@/services/private/TimeTracking";


export default {
  components: {
    EditProjectForm,
    OtherUserProjectForm,
    ShiftDetails,
    AddShiftModal,
    ICShiftDetails,
    DeleteShiftModal,
    TimeTrackingDetails,
  },
  data() {
    return {
      currentuser_role: currentuser_role(),
    };
  },
  computed: {
    isRoleAdminOrDispatch() {
      const user = storage().getUser();
      const role = user && user.role;
      return role && (role.role_code === "A" || role.role_code === "D");
    },
    firstName() {
      return ``;
    },
  },
  setup() {
    const i18n = useI18n();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    let projectData = reactive({ value: {} });
    let shifts = reactive({ value: [] });
    let timeTrackingData = reactive({ value: [] });
    let adminDispatchShiftsRequest = reactive({ value: [] });
    let isProjectDataLoaded = ref(false);
    let isShiftDataLoaded = ref(false);
    let loader = ref(false);
    let shiftMode = ref("");
    let shiftData = reactive({ value: {} });
    let addShiftModalVisible = ref(false);
    let shiftStartDateTime = ref("");
    let shiftEndDateTime = ref("");
    let projectStartDateTime = ref("");
    let projectEndDateTime = ref("");
    let isOCProject = ref("");
    let isAccessAllowed = ref(false);
    let deleteShiftModalVisible = ref(false);
    let deleteShiftData = reactive({ value: {} });
    let showTimeTracking = ref(false);
    let showShifts = ref(true);

    function toggleShowTimeTracking(state) {
      showTimeTracking.value = state;
      showShifts.value = false;

      console.log("timeTrackingData:", timeTrackingData);
    }

    function toggleShowShift() {
      showShifts.value = !showShifts.value;
      showTimeTracking.value = false;
      console.log("showShifts", showShifts.value);
      console.log(showShifts.value);
    }

    function isRoleAdminOrDispatch() {
      const user = storage().getUser();
      const role = user && user.role;
      return role && (role.role_code === "A" || role.role_code === "D");
    }

    function checkShiftProjectTiming() {
      let updateDate = { flag: false };
      const isBefore = date().isBefore(
        shiftStartDateTime.value,
        projectStartDateTime.value
      );
      const isAfter = date().isAfter(
        shiftEndDateTime.value,
        projectEndDateTime.value
      );
      if (isBefore) {
        updateDate = {
          flag: true,
          start_date: date().formatDate(shiftStartDateTime.value, "YYYY-MM-DD"),
          end_date: date().formatDate(projectEndDateTime.value, "YYYY-MM-DD"),
          start_time: date().formatDate(shiftStartDateTime.value, "HH:mm"),
          end_time: date().formatDate(projectEndDateTime.value, "HH:mm"),
        };
      } else if (isAfter) {
        updateDate = {
          flag: true,
          start_date: date().formatDate(
            projectStartDateTime.value,
            "YYYY-MM-DD"
          ),
          end_date: date().formatDate(shiftEndDateTime.value, "YYYY-MM-DD"),
          start_time: date().formatDate(projectStartDateTime.value, "HH:mm"),
          end_time: date().formatDate(shiftEndDateTime.value, "HH:mm"),
        };
      }
      return updateDate;
    }
    async function getProjectDetails() {
      isAccessAllowed.value = false;
      const project_id = route.params.id;
      const result = await GETPROJECTBYIDAPI(project_id);
      if (result && result.status === 200) {
        projectData.value = result.data.data[0];
        if (
          !isRoleAdminOrDispatch &&
          (result.data.data[0].is_deleted || result.data.data[0].is_cancelled)
        ) {
          toast.error(i18n.$t(`projectDetails.projectDeletedMsg`));
          router.push({ name: "Error" });
        }
        isAccessAllowed.value = true;
        projectStartDateTime.value = date().formatDate(
          `${result.data.data[0].start_date} ${date().formatTime(
            result.data.data[0].start_time,
            "HH:mm"
          )}`,
          "YYYY-MM-DD HH:mm"
        );
        if (result.data.data[0].end_date !== null) {
          const endtime =
            result.data.data[0].end_time !== null
              ? `${date().formatTime(result.data.data[0].end_time, "HH:mm")}`
              : "00:00";
          projectEndDateTime.value = date().formatDate(
            `${result.data.data[0].end_date} ${date().formatTime(
              endtime,
              "HH:mm"
            )}`,
            "YYYY-MM-DD HH:mm"
          );
        } else {
          projectEndDateTime.value = date().formatDate(
            `${result.data.data[0].start_date} ${date().formatTime(
              result.data.data[0].start_time,
              "HH:mm"
            )}`,
            "YYYY-MM-DD HH:mm"
          );
        }
        const user = storage().getUser();
        const company_id = user && user.company_id;
        if (company_id === result.data.data[0].company_id) {
          isOCProject.value = true;
        } else {
          isOCProject.value = false;
        }
        isProjectDataLoaded.value = true;
      }
    }
    function shiftDateTime(shiftDate, shiftTime) {
      if (shiftDate !== null) {
        return new Date(
          date().formatDate(`${shiftDate} ${shiftTime}`, "YYYY-MM-DD HH:mm")
        );
      }
      return "-";
    }
    async function getShiftsDetails() {
      isAccessAllowed.value = false;
      const user = storage().getUser();
      const role = user && user.role;
      const project_id = route.params.id;
      if (role && (role.role_code === "A" || role.role_code === "D")) {
        isAccessAllowed.value = true;
        const userResult = await GETOTHERUSERROLEPROJECTSHIFTSAPI({
          user_id: user && user.user_id,
          project_id,
          flag: false,
        });
        if (userResult && userResult.status === 200) {
          const { data } = userResult.data;
          if (data.length > 0) {
            adminDispatchShiftsRequest.value = data.map((s) => {
              return {
                ...s,
                shift_status: i18n.$t(`project.status.${s.shift_status}`),
                shift_status_en: s.shift_status,
                shift_start_date_time: `${s.shift_date} ${s.shift_start_time}`,
                shift_end_date_time: `${s.shift_end_date} ${s.shift_end_time}`,
              };
            });
          }
        }
        const result = await GETPROJECTSHIFTSAPI({ project_id, flag: false });
        if (result && result.status === 200) {
          isShiftDataLoaded.value = true;
          const { data } = result.data;
          if (data.length > 0) {
            const sortedShifts = getSortedDate(data);
            const firstShift = sortedShifts && sortedShifts.firstShift;
            let endShift = sortedShifts && sortedShifts.endShift;
            shiftStartDateTime.value = firstShift.shift_time;
            shiftEndDateTime.value = endShift.shift_time;
            shifts.value = data.map((s) => {
              return {
                ...s,
                shift_status: i18n.$t(`project.status.${s.shift_status}`),
                shift_status_en: s.shift_status,
                shift_start_date_time: `${s.shift_date} ${s.shift_start_time}`,
                shift_end_date_time: `${s.shift_end_date} ${s.shift_end_time}`,
              };
            });
          }
        }
        const ttResult = await GETTIMETRACKINGBYPROJECTIDAPI({ project_id });
        console.log("ttResult.status=" + ttResult.status);
        if (ttResult && ttResult.status === 200) {
          timeTrackingData.value = ttResult.data.data;
        }
      } else {
        const result = await GETOTHERUSERROLEPROJECTSHIFTSAPI({
          user_id: user && user.user_id,
          project_id,
          flag: false,
        });
        if (result && result.status === 200) {
          isShiftDataLoaded.value = true;
          const { data } = result.data;
          if (data.length > 0) {
            const sortedShifts = getSortedDate(data);
            const firstShift = sortedShifts && sortedShifts.firstShift;
            let endShift = sortedShifts && sortedShifts.endShift;
            shiftStartDateTime.value = firstShift.shift_time;
            shiftEndDateTime.value = endShift.shift_time;
            shifts.value = data.map((s) => {
              return {
                ...s,
                shift_status: i18n.$t(`project.status.${s.shift_status}`),
                shift_status_en: s.shift_status,
                shift_start_date_time: `${s.shift_date} ${s.shift_start_time}`,
                shift_end_date_time: `${s.shift_end_date} ${s.shift_end_time}`,
              };
            });
            isAccessAllowed.value = true;
          } else {
            toast.error(i18n.$t(`projectDetails.projectNoAccessMsg`));
            router.push({ name: "Error" });
          }
        }
      }
    }
    async function getICAdminShiftDetails() {
      const user = storage().getUser();
      const role = user && user.role;
      const project_id = route.params.id;
      if (role && (role.role_code === "A" || role.role_code === "D")) {
        const result = await GETSHIFTBYPARTNERAPI({
          user_id: user && user.user_id,
          project_id,
          flag: false,
        });
        if (result && result.status === 200) {
          isShiftDataLoaded.value = true;
          const { data } = result.data;
          if (data.length > 0) {
            const sortedShifts = getSortedDate(data);
            const firstShift = sortedShifts && sortedShifts.firstShift;
            let endShift = sortedShifts && sortedShifts.endShift;
            shiftStartDateTime.value = firstShift.shift_time;
            shiftEndDateTime.value = endShift.shift_time;
            shifts.value = data.map((s) => {
              return {
                ...s,
                shift_status: i18n.$t(`project.status.${s.shift_status}`),
                shift_status_en: s.shift_status,
                shift_start_date_time: shiftDateTime(
                  s.shift_date,
                  s.shift_start_time
                ),
                shift_end_date_time: shiftDateTime(
                  s.shift_end_date,
                  s.shift_end_time
                ),
              };
            });
          }
        }
        const userResult = await GETOTHERUSERROLEPROJECTSHIFTSAPI({
          user_id: user && user.user_id,
          project_id,
          flag: false,
        });
        if (userResult && userResult.status === 200) {
          const { data } = userResult.data;
          if (data.length > 0) {
            adminDispatchShiftsRequest.value = data.map((s) => {
              return {
                ...s,
                shift_status: i18n.$t(`project.status.${s.shift_status}`),
                shift_status_en: s.shift_status,
                shift_start_date_time: shiftDateTime(
                  s.shift_date,
                  s.shift_start_time
                ),
                shift_end_date_time: shiftDateTime(
                  s.shift_end_date,
                  s.shift_end_time
                ),
              };
            });
          }
        }
      } else {
        const userResult = await GETOTHERUSERROLEPROJECTSHIFTSAPI({
          user_id: user && user.user_id,
          project_id,
          flag: false,
        });
        if (userResult && userResult.status === 200) {
          isShiftDataLoaded.value = true;
          const { data } = userResult.data;
          if (data.length > 0) {
            const sortedShifts = getSortedDate(data);
            const firstShift = sortedShifts && sortedShifts.firstShift;
            let endShift = sortedShifts && sortedShifts.endShift;
            shiftStartDateTime.value = firstShift.shift_time;
            shiftEndDateTime.value = endShift.shift_time;
            shifts.value = data.map((s) => {
              return {
                ...s,
                shift_status: i18n.$t(`project.status.${s.shift_status}`),
                shift_status_en: s.shift_status,
                shift_start_date_time: shiftDateTime(
                  s.shift_date,
                  s.shift_start_time
                ),
                shift_end_date_time: shiftDateTime(
                  s.shift_end_date,
                  s.shift_end_time
                ),
              };
            });
          }
        }
      }
    }
    function getSortedDate(shift) {
      const minDate = shift.reduce((a, b) => {
        return new Date(a.shift_date) < new Date(b.shift_date) ? a : b;
      });
      const maxDate = shift.reduce((a, b) => {
        return new Date(a.shift_end_date) > new Date(b.shift_end_date) ? a : b;
      });
      const firstShift = {
        shift_date: `${minDate.shift_start_time}`,
        shift_time: date().formatDate(
          `${minDate.shift_date} ${date().formatTime(
            minDate.shift_start_time,
            "HH:mm"
          )}`,
          "YYYY-MM-DD HH:mm"
        ),
      };
      let project = projectData.value;
      const isEndDateSet = project && project.end_date;
      let isAfter = false;
      if (isEndDateSet !== null) {
        isAfter = date().isAfter(isEndDateSet, maxDate.shift_end_date);
      }
      const endShift =
        shift.length > 1
          ? {
            shift_date: `${maxDate.shift_end_date}`,
            shift_time: date().formatDate(
              `${maxDate.shift_end_date} ${date().formatTime(
                maxDate.shift_end_time,
                "HH:mm"
              )}`,
              "YYYY-MM-DD HH:mm"
            ),
          }
          : isEndDateSet !== null && isAfter
            ? {
              shift_date: `${project.end_date}`,
              shift_time: date().formatDate(
                `${project.end_date} ${date().formatTime(
                  project.end_time,
                  "HH:mm"
                )}`,
                "YYYY-MM-DD HH:mm"
              ),
            }
            : {
              shift_date: `${minDate.shift_end_date}`,
              shift_time: date().formatDate(
                `${minDate.shift_end_date} ${date().formatTime(
                  minDate.shift_end_time,
                  "HH:mm"
                )}`,
                "YYYY-MM-DD HH:mm"
              ),
            };
      return { firstShift, endShift };
    }
    function toggleAddShiftModal(state) {
      addShiftModalVisible.value = state;
      shiftMode.value = state === true ? "CREATE" : "";
    }
    function toggleManagerShiftModal(state) {
      addShiftModalVisible.value = state;
      let project = projectData.value;
      shiftMode.value = state === true ? "ADDMANAGER" : "";

      shiftData.value.project_id = project.project_id;
      // shiftData.value.shift_id: this.shiftData && this.shiftData.shift_id,
      shiftData.value.shift_name = "PROJEKTLEITUNG";
      shiftData.value.shift_discipline = "Verwaltung";
      shiftData.value.shift_date = project.start_date;
      shiftData.value.shift_start_time = project.start_time;
      shiftData.value.shift_end_time = project.end_time;
      shiftData.value.shift_count = "1";
      shiftData.value.shift_status = "created";
      shiftData.value.shift_description = "";
      shiftData.value.is_manager_shift = true;
    }
    function hideAddShiftModal() {
      addShiftModalVisible.value = false;
      shiftData.value = {};
      shiftMode.value = "";
    }
    async function addShiftApi(values) {
      loader.value = true;
      const {
        shift_name,
        shift_date,
        shift_end_date,
        shift_start_time,
        shift_end_time,
        shift_count,
        shift_discipline,
        shift_description,
        is_manager_shift,
      } = values;
      const data = {
        project_id: route.params.id,
        shift_name,
        shift_date,
        shift_end_date,
        shift_start_time,
        shift_end_time,
        shift_count,
        shift_discipline,
        shift_description,
        is_manager_shift,
      };
      const result = await CREATESHIFTAPI(data);
      loader.value = false;
      if (result && result.status === 200) {
        toggleAddShiftModal(false);
        toast.success(i18n.$t("projectDetails.shifts.response.created"));
        await getShiftsDetails();
        const isChanged = checkShiftProjectTiming();
        if (isChanged && isChanged.flag === true) {
          const newProjectData = {
            start_date: isChanged.start_date,
            end_date: isChanged.end_date,
            start_time: isChanged.start_time,
            end_time: isChanged.end_time,
          };
          await updateProject(newProjectData);
        }
      } else {
        toast.error(i18n.$t("projectDetails.shifts.response.failed"));
      }
    }
    async function updateShiftApi(values) {
      loader.value = true;
      const {
        shift_id,
        shift_name,
        shift_date,
        shift_end_date,
        shift_start_time,
        shift_end_time,
        shift_count,
        shift_status,
        shift_discipline,
        shift_description,
      } = values;
      const data = {
        project_id: route.params.id,
        shift_id,
        shift_name,
        shift_date,
        shift_end_date,
        shift_start_time,
        shift_end_time,
        shift_count,
        shift_status,
        shift_discipline,
        shift_description,
      };
      const result = await UPDATESHIFTAPI(data);
      loader.value = false;
      if (result && result.status === 200) {
        toggleAddShiftModal(false);
        toast.success(i18n.$t("projectDetails.shifts.response.updated"));
        await getShiftsDetails();
        const isChanged = checkShiftProjectTiming();
        if (isChanged && isChanged.flag === true) {
          const newProjectData = {
            start_date: isChanged.start_date,
            end_date: isChanged.end_date,
            start_time: isChanged.start_time,
            end_time: isChanged.end_time,
          };
          await updateProject(newProjectData);
        }
      } else {
        toast.error(i18n.$t("projectDetails.shifts.response.failedUpdated"));
      }
    }
    function openEditMode(data) {
      shiftMode.value = "EDIT";
      shiftData.value = data;
      addShiftModalVisible.value = true;
    }
    function confirmDeleteShift(data) {
      deleteShiftData.value.shift_id = data.shift_id;
      deleteShiftModalVisible.value = true;
    }
    function hideDeleteShiftModal() {
      deleteShiftData.value.shift_id = "";
      deleteShiftModalVisible.value = false;
    }
    async function deleteShiftApi() {
      loader.value = true;
      const data = { shift_id: deleteShiftData.value.shift_id };
      const result = await DELETESHIFTAPI(data);
      loader.value = false;
      if (result && result.status === 200) {
        hideDeleteShiftModal();
        toast.success(i18n.$t("projectDetails.shifts.response.shiftDeleted"));
        await getShiftsDetails();
      } else {
        toast.error(
          i18n.$t("projectDetails.shifts.response.shiftFailedDeleted")
        );
      }
    }
    async function updateProject(values) {
      const { end_date, end_time, start_date, start_time } = values;
      const { value } = projectData;
      const data = {
        project_id: value.project_id,
        project_name: value.project_name,
        location_id: value.location_id,
        project_status: value.project_status,
        project_description: value.project_description,
        end_date,
        end_time,
        start_date,
        start_time,
      };
      await UPDATEPROJECTAPI(data);
      await getProjectDetails();
    }

    onMounted(async () => {
      await getProjectDetails();
      if (isOCProject && isOCProject.value === false) {
        await getICAdminShiftDetails();
      } else {
        await getShiftsDetails();
      }
    });
    return {
      toggleShowTimeTracking,
      toggleShowShift,
      i18n,
      projectData,
      shifts,
      isShiftDataLoaded,
      adminDispatchShiftsRequest,
      isProjectDataLoaded,
      isOCProject,
      loader,
      shiftMode,
      shiftData,
      timeTrackingData,
      addShiftModalVisible,
      shiftStartDateTime,
      shiftEndDateTime,
      projectStartDateTime,
      projectEndDateTime,
      isAccessAllowed,
      deleteShiftModalVisible,
      getSortedDate,
      getShiftsDetails,
      getICAdminShiftDetails,
      openEditMode,
      hideAddShiftModal,
      toggleAddShiftModal,
      toggleManagerShiftModal,
      addShiftApi,
      updateShiftApi,
      getProjectDetails,
      confirmDeleteShift,
      hideDeleteShiftModal,
      deleteShiftApi,
      showTimeTracking,
      showShifts,
    };
  },
};
</script>
<style>
.profile-header {
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}

.profile-background {
  background: linear-gradient(90deg,
      rgba(124, 123, 159, 1) 0%,
      rgba(29, 26, 80, 1) 67%) !important;
}
</style>
