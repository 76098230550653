<template>
  <div>
    <div style="margin-bottom: 20px;"></div>
    <div class="mb-5 card">
      <el-table class="table-responsive table-flush rounded" header-row-class-name="thead-light"
        header-cell-class-name="px-3" cell-class-name="px-3 py-2" :data="timeTracking">
        <!-- Remove prop or set it correctly if needed for sorting -->
        <el-table-column :label="'User ID'" prop="user_id" min-width="200" sortable="custom">
          <template v-slot="{ row }">
            <div>{{ row.first_name + ' ' + row.last_name }}</div>          
          </template>
        </el-table-column>
        <el-table-column :label="'Shift ID'" prop="shift_id" min-width="200" sortable="custom">
          <template v-slot="{ row }">
            <div>{{ row.shift_id }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="'Beginn'" prop="time_record_start_time" min-width="200" sortable="custom">
          <template v-slot="{ row }">
            <div>{{ row.time_record_start_time }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="'Ende'" prop="time_record_end_time" min-width="200" sortable="custom">
          <template v-slot="{ row }">
            <div>{{ row.time_record_end_time }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="'Typ'" prop="time_record_type" min-width="200" sortable="custom">
          <template v-slot="{ row }">
            <div>{{ row.time_record_type }}</div>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <div style="margin-bottom: 20px;"></div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElPopover,
  ElLoading,
} from "element-plus";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  SHIFTSTATUS,
  PROJECTSTATUSCOLOR,
  SHIFTSTATUSCOLORDOT,
} from "@/util/constant";
import date from "@/util/date";
import { useI18n } from "@/util/i18n";
import storage from "@/util/storage";
import { useToast } from "vue-toastification";



export default {
  props: {
    timeTracking: Object,
  },
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElPopover.name]: ElPopover,

  },
  data() {
    return {

    };
  },
  computed: {},
  setup(props, context) {
    const i18n = useI18n();
    const toast = useToast();
    const route = useRoute();


    onMounted(() => {
      console.log("TimeTrackingDetails mounted");
      console.log("TimeTrackingDetails - timeTracking", props.timeTracking);

    });

    return {
      i18n,
    };
  },
};
</script>
<style>
.el-popover {
  z-index: 5000 !important;
}

.highlightExpand {
  background-color: #e5e5e5 !important;
}
</style>
<style scoped lang="scss">
.project_status {

  &:disabled,
  &[readonly] {
    background-color: #fff !important;
  }
}

.datepicker {

  &:disabled,
  &[readonly] {
    background-color: #fff !important;
  }
}

body.modal-open {
  overflow: hidden;
}

.project-rows {
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin-right: 0px;
  cursor: pointer;
}

.project-col {
  padding-left: 0px;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  font-size: 0.875rem;
}

.margin-left {
  color: #212529;
  margin-left: 5px;
}

.dropdown-item {
  text-align: center;
}

.el-dropdown-menu {
  background-color: #e5e5e5 !important;
}

.btn-hover:hover {
  box-shadow: none;
  transform: none;
}

.btn {
  height: 29px;
}
</style>